import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { createSelector } from '@reduxjs/toolkit';
import moment from 'moment';
import { Typography } from '@mui/material';
import ModalWindow from '../../ModalWindow';

import i18n from '../../../i18n';
import { closeModalWindow, openModalWindow } from '../../ModalWindow/slice';
import { TARIFFS_LIST_ID, HiSTORICAL_TARIFF_LIST_ID } from '../constants';
import { ReactComponent as ArrLeftSVG } from '../../../assets/images/svg/arrow-left.svg';
import { ReactComponent as ArrRightSVG } from '../../../assets/images/svg/arrow-right.svg';
import { ReactComponent as PlusSVG } from '../../../assets/images/svg/plus-new.svg';
import { Tooltip } from '../../../components';

const dataListSelectorTariffs = createSelector(
  (state) => state.dataList?.[TARIFFS_LIST_ID]?.data || [],
  (data) => [...data]?.sort((a, b) => moment(b.from).diff(moment(a.from)))
    .map((item, index, arr) => ({
      ...item,
      from: moment(item.from).format('MMM DD, YYYY'),
      to: moment(arr[index - 1]?.from).subtract(1, 'day').format('MMM DD, YYYY')
    }))
);

export const HistoricalTariffsWindow = () => {
  const data = useSelector(dataListSelectorTariffs);

  const dispatch = useDispatch();

  const handleOnClose = () => dispatch(closeModalWindow({ modalID: TARIFFS_LIST_ID }));
  const handleOpenHistoricalTariff = ({ tariffId, isNew }) => dispatch(openModalWindow({
    modalID: HiSTORICAL_TARIFF_LIST_ID,
    data: { tariffId, isNew }
  }));

  return (
    <ModalWindow
      modalID={TARIFFS_LIST_ID}
      customStyle={{ zIndex: 1001 }}
    >
      <div
        className="modal-header"
        style={{
          padding: '30px 40px',
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'space-between'
        }}
      >
        <h5
          className="modal-title"
          style={{
            margin: 0,
            display: 'flex',
            alignItems: 'center',
            fontSize: '18px',
            textTransform: 'uppercase'
          }}
        >
          <button type="button" className="button button-third pr-2 pl-2" onClick={handleOnClose}>
            <ArrLeftSVG />
          </button>
          <span>
            {i18n.t('tariffHistory')}
          </span>
        </h5>
        <Typography
          variant="button"
          onClick={() => handleOpenHistoricalTariff({ isNew: true })}
          className="button button-third"
          sx={{
            color: 'primary.main',
            cursor: 'pointer',
            textTransform: 'none',
            fontSize: 14
          }}
        >
          <PlusSVG />
          {i18n.t('addTariff')}
        </Typography>
      </div>
      <div className="modal-body">
        <Typography
          variant="h6"
          sx={{
            mt: 2,
            color: '#131313',
            ml: '36px',
            textTransform: 'uppercase',
            fontWeight: 400,
            fontSize: 16
          }}
        >
          {i18n.t('tariffs')}
          {' '}
          <Tooltip title={i18n.t('tariffsTooltip')} placement="right" />
        </Typography>
        {data.length > 1 ? (
          <ul className="options-list" style={{ padding: '16px 36px 36px' }}>
            {data.filter(({ state }) => state === 'historic').map((tariff) => (
              <li
                key={tariff._id}
                className="options-item"
                style={{ display: 'flex', justifyContent: 'space-between' }}
                onClick={() => handleOpenHistoricalTariff({ tariffId: tariff._id, isNew: false })}
              >
                <div>
                  <span className="">{tariff.from}</span>
                  {' '}
                  –
                  {' '}
                  <span className="">{tariff.to}</span>
                </div>
                <ArrRightSVG />
              </li>
            ))}
          </ul>
        ) : (
          <Typography
            sx={{ padding: '16px 36px 36px', color: 'secondary.main', opacity: 0.5 }}
          >
            {i18n.t('chartsNoData')}
          </Typography>
        )}
      </div>
    </ModalWindow>

  );
};

HistoricalTariffsWindow.propTypes = {};
